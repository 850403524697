// Generated by ReScript, PLEASE EDIT WITH CARE


var English_track_order_heading = "Track Order";

var English_booking_mobile_number_heading = "Booking mobile Number";

var English_track_order_button_title = "Track Order";

var English_allocated_driver_text = "Driver yet to be assigned";

var English_cancelled_driver_text = "No Driver assigned";

var English_status = {
  open_order_status: "Searching for a driver",
  allocated_order_status: "Searching for a driver",
  accepted_order_status: "Driver is on his way to pickup",
  live_order_status: "Driver is on the way to drop",
  loading_order_status: "Awaiting update…",
  unloading_order_status: "Awaiting update…",
  ended_order_status: "Driver has delivered the goods",
  completed_order_status: "Driver has delivered the goods",
  cancelled_order_status: "Trip is cancelled",
  rescheduled_order_status: "Trip is cancelled"
};

var English_show_order_details_text = "SHOW ORDER DETAILS";

var English_hide_order_details_text = "HIDE ORDER DETAILS";

var English_order_placed_by_text = "Order Placed By";

var English_order_details_text = "Order Details";

var English_order_id_text = "Order ID : ";

var English_contact_support_text = "Contact Support";

var English_experiencing_issues_text = "Still experiencing issues?";

var English = {
  track_order_heading: English_track_order_heading,
  booking_mobile_number_heading: English_booking_mobile_number_heading,
  track_order_button_title: English_track_order_button_title,
  allocated_driver_text: English_allocated_driver_text,
  cancelled_driver_text: English_cancelled_driver_text,
  status: English_status,
  show_order_details_text: English_show_order_details_text,
  hide_order_details_text: English_hide_order_details_text,
  order_placed_by_text: English_order_placed_by_text,
  order_details_text: English_order_details_text,
  order_id_text: English_order_id_text,
  contact_support_text: English_contact_support_text,
  experiencing_issues_text: English_experiencing_issues_text
};

var TR_track_order_heading = "Siparişi Takip Et";

var TR_booking_mobile_number_heading = "Rezervasyon cep numarası";

var TR_track_order_button_title = "Siparişi Takip Et";

var TR_allocated_driver_text = "Sürücü henüz atanmadı";

var TR_cancelled_driver_text = "Sürücü atanmadı";

var TR_status = {
  open_order_status: "Sürücü aranıyor",
  allocated_order_status: "Sürücü aranıyo",
  accepted_order_status: "Sürücü teslim almaya gidiyor",
  live_order_status: "Sürücü teslim etmeye gidiyorp",
  loading_order_status: "Güncelleme bekleniyor...",
  unloading_order_status: "Güncelleme bekleniyor...",
  ended_order_status: "Sürücü ürünleri teslim etti",
  completed_order_status: "Sürücü ürünleri teslim etti",
  cancelled_order_status: "Seyahat iptal edildi",
  rescheduled_order_status: "Seyahat iptal edildi"
};

var TR_show_order_details_text = "SİPARİŞ DETAYLARINI GÖSTER";

var TR_hide_order_details_text = "SIPARİŞ DETAYLARINI GİZLE";

var TR_order_placed_by_text = "Sipariş Veren";

var TR_order_details_text = "Sipariş Detayları";

var TR_order_id_text = "Sipariş Kimliği : ";

var TR_contact_support_text = "Destek ile İletişime Geç";

var TR_experiencing_issues_text = "Hala sorun mu yaşıyorsunuz?";

var TR = {
  track_order_heading: TR_track_order_heading,
  booking_mobile_number_heading: TR_booking_mobile_number_heading,
  track_order_button_title: TR_track_order_button_title,
  allocated_driver_text: TR_allocated_driver_text,
  cancelled_driver_text: TR_cancelled_driver_text,
  status: TR_status,
  show_order_details_text: TR_show_order_details_text,
  hide_order_details_text: TR_hide_order_details_text,
  order_placed_by_text: TR_order_placed_by_text,
  order_details_text: TR_order_details_text,
  order_id_text: TR_order_id_text,
  contact_support_text: TR_contact_support_text,
  experiencing_issues_text: TR_experiencing_issues_text
};

export {
  English ,
  TR ,
  
}
/* No side effect */
