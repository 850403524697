// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config$FrontendWebsite from "../../../app/Config.bs.js";

var baseRouterUrl = Config$FrontendWebsite.Env.getFEUrl(undefined);

var personal = "personal";

var business = "business";

function getRequirementStrings(str) {
  switch (str) {
    case "Business" :
        return business;
    case "Personal" :
        return personal;
    default:
      return str;
  }
}

var Select = {
  personal: personal,
  business: business,
  getRequirementStrings: getRequirementStrings
};

var terms_of_service_url = baseRouterUrl + "/terms-and-conditions";

var English_heading = "";

var English_driverPartnerHeading = "DRIVE WITH US";

var English_driverPartnerHeadingDesc = "turn kms into money";

var English_driverPartnerJoinUsTitle = "JOIN US";

var English_navbar_title_1 = "City Tempo";

var English_navbar_title_2 = "Driver Partners";

var English_form_heading_1 = "City";

var English_form_heading_2 = "Pick Up Address";

var English_form_heading_3 = "DropOff Address";

var English_form_heading_4 = "Mobile Number";

var English_form_heading_5 = "Enter Mobile Number";

var English_form_heading_6 = "Name(Optional)";

var English_form_heading_7 = "Requirement";

var English_form_button_title = "Get an Estimate";

var English_form_consent_box_title = "By checking this, you give consent to Porter to reach out to you via phone/WhatsApp to help with booking, etc";

var English_about_us_link_text = "About Us";

var English_privacy_policy_link_text = "Privacy Policy";

var English_terms_of_service_link_text = "Terms & Conditions";

var English_refund_policy_link_text = "";

var English_tariff_subtitle_1 = "Pricing may vary based on your locality.";

var English_tariff_subtitle_2 = "Road tax, parking etc as applicable over and above ride fare.";

var English_tariff_button_title = "Know More";

var English_fare_start_text = "Fares starting from";

var English_companyCinNo = "";

var English_companyCopyrightText = "";

var English = {
  Select: Select,
  heading: English_heading,
  select_city_label: "Select City",
  driverPartnerHeading: English_driverPartnerHeading,
  driverPartnerHeadingDesc: English_driverPartnerHeadingDesc,
  driverPartnerJoinUsTitle: English_driverPartnerJoinUsTitle,
  navbar_title_1: English_navbar_title_1,
  navbar_title_2: English_navbar_title_2,
  form_heading_1: English_form_heading_1,
  form_heading_2: English_form_heading_2,
  form_heading_3: English_form_heading_3,
  form_heading_4: English_form_heading_4,
  form_heading_5: English_form_heading_5,
  form_heading_6: English_form_heading_6,
  form_heading_7: English_form_heading_7,
  form_button_title: English_form_button_title,
  form_consent_box_title: English_form_consent_box_title,
  about_us_link_text: English_about_us_link_text,
  privacy_policy_link_text: English_privacy_policy_link_text,
  terms_of_service_link_text: English_terms_of_service_link_text,
  terms_of_service_url: terms_of_service_url,
  refund_policy_link_text: English_refund_policy_link_text,
  tariff_subtitle_1: English_tariff_subtitle_1,
  tariff_subtitle_2: English_tariff_subtitle_2,
  tariff_button_title: English_tariff_button_title,
  fare_start_text: English_fare_start_text,
  download_porter_button_label: "Download Porter",
  pickup_address_placeholder: "Enter PickUp Address",
  dropoff_address_placeholder: "Enter DropOff Address",
  get_estimate_title: "Get Trip Estimate",
  footer_title: "BOOK NOW",
  optional_placeholder: "Optional",
  companyCinNo: English_companyCinNo,
  companyCopyrightText: English_companyCopyrightText,
  newCompanyCopyrightText: "",
  newCompanyCinNo: ""
};

var personal$1 = "şahsi";

var business$1 = "iş";

function getRequirementStrings$1(str) {
  switch (str) {
    case "Business" :
        return business$1;
    case "Personal" :
        return personal$1;
    default:
      return str;
  }
}

var Select$1 = {
  personal: personal$1,
  business: business$1,
  getRequirementStrings: getRequirementStrings$1
};

var terms_of_service_url$1 = baseRouterUrl + "/terms-and-conditions";

var TR_heading = "";

var TR_select_city_label = "Şehir seçiniz";

var TR_driverPartnerHeading = "BİZİMLE SÜRÜN";

var TR_driverPartnerHeadingDesc = "km'leri paraya dönüştürün";

var TR_driverPartnerJoinUsTitle = "BİZE KATILIN";

var TR_navbar_title_1 = "şehir temposu";

var TR_navbar_title_2 = "Sürücü Ortaklar";

var TR_form_heading_1 = "Şehir";

var TR_form_heading_2 = "Teslim Alma Adresi";

var TR_form_heading_3 = "Teslim Etme Adresi";

var TR_form_heading_4 = "Cep Telefonu Numarası";

var TR_form_heading_5 = "Cep Telefonu Numarasını Girin";

var TR_form_heading_6 = "İsim (İsteğe bağlı)";

var TR_form_heading_7 = "İhtiyaç";

var TR_form_button_title = "Tahmini Fiyat Alın";

var TR_form_consent_box_title = "Bunu işaretleyerek, rezervasyon vb. konularda yardımcı olması için Porter'ın size telefon/WhatsApp aracılığıyla ulaşmasına izin vermiş olursunuz.";

var TR_about_us_link_text = "Hakkımızda";

var TR_privacy_policy_link_text = "Gizlilik Politikası";

var TR_terms_of_service_link_text = "Hüküm ve Koşullar";

var TR_refund_policy_link_text = "";

var TR_tariff_subtitle_1 = "Fiyatlandırma bulunduğunuz yere göre değişiklik gösterebilir.";

var TR_tariff_subtitle_2 = "Sürüş ücretinin üzerine yol vergisi, otopark vb. masraflar eklenebilir.";

var TR_tariff_button_title = "Daha Fazla Bilgi";

var TR_fare_start_text = "Şu fiyatlardan başlayan ücretler";

var TR_download_porter_button_label = "Porter Uygulamasını İndirin";

var TR_pickup_address_placeholder = "Teslim Alma Adresini Girin";

var TR_dropoff_address_placeholder = "Teslim Etme Adresini Girin";

var TR_get_estimate_title = "Yolculuk Tahmini Alın";

var TR_footer_title = "HEMEN REZERVASYON YAPIN";

var TR_optional_placeholder = "İsteğe Bağlı";

var TR_companyCinNo = "";

var TR_companyCopyrightText = "";

var TR_newCompanyCopyrightText = "";

var TR_newCompanyCinNo = "";

var TR = {
  Select: Select$1,
  heading: TR_heading,
  select_city_label: TR_select_city_label,
  driverPartnerHeading: TR_driverPartnerHeading,
  driverPartnerHeadingDesc: TR_driverPartnerHeadingDesc,
  driverPartnerJoinUsTitle: TR_driverPartnerJoinUsTitle,
  navbar_title_1: TR_navbar_title_1,
  navbar_title_2: TR_navbar_title_2,
  form_heading_1: TR_form_heading_1,
  form_heading_2: TR_form_heading_2,
  form_heading_3: TR_form_heading_3,
  form_heading_4: TR_form_heading_4,
  form_heading_5: TR_form_heading_5,
  form_heading_6: TR_form_heading_6,
  form_heading_7: TR_form_heading_7,
  form_button_title: TR_form_button_title,
  form_consent_box_title: TR_form_consent_box_title,
  about_us_link_text: TR_about_us_link_text,
  privacy_policy_link_text: TR_privacy_policy_link_text,
  terms_of_service_link_text: TR_terms_of_service_link_text,
  terms_of_service_url: terms_of_service_url$1,
  refund_policy_link_text: TR_refund_policy_link_text,
  tariff_subtitle_1: TR_tariff_subtitle_1,
  tariff_subtitle_2: TR_tariff_subtitle_2,
  tariff_button_title: TR_tariff_button_title,
  fare_start_text: TR_fare_start_text,
  download_porter_button_label: TR_download_porter_button_label,
  pickup_address_placeholder: TR_pickup_address_placeholder,
  dropoff_address_placeholder: TR_dropoff_address_placeholder,
  get_estimate_title: TR_get_estimate_title,
  footer_title: TR_footer_title,
  optional_placeholder: TR_optional_placeholder,
  companyCinNo: TR_companyCinNo,
  companyCopyrightText: TR_companyCopyrightText,
  newCompanyCopyrightText: TR_newCompanyCopyrightText,
  newCompanyCinNo: TR_newCompanyCinNo
};

export {
  baseRouterUrl ,
  English ,
  TR ,
  
}
/* baseRouterUrl Not a pure module */
