// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDom from "react-dom";
import * as App$FrontendWebsite from "./App.bs.js";
import * as Config$FrontendWebsite from "./Config.bs.js";
import * as Sentry$FrontendWebsite from "../utils/library/sentry/Sentry.bs.js";

var match = Config$FrontendWebsite.Env.env;

var exit = 0;

switch (match) {
  case "prod" :
  case "production" :
      exit = 1;
      break;
  default:
    
}

if (exit === 1) {
  Sentry$FrontendWebsite.init({
        dsn: Config$FrontendWebsite.Env.sentry_dsn,
        env: "production",
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 0.0
      });
}

var root = document.querySelector("#root");

if (!(root == null)) {
  ReactDom.render(React.createElement(App$FrontendWebsite.make, {}), root);
}

export {
  
}
/*  Not a pure module */
